import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'react-i18next'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import required modules
import { Pagination, Autoplay } from 'swiper'
import { useViewport } from '@/hooks/useViewport'

const Testimonial = () => {
  const { isTablet } = useViewport()
  const { t } = useTranslation()
  return (
    <section className="section section-lg" style={{paddingBottom:'6rem', backgroundColor:'#F6FBFD'}}>
      <div className="container">
        <h3 className="text-center" style={{ marginBottom: '3rem' }}>
        {t('trustUs')}
        </h3>
        <Swiper
          slidesPerView={(isTablet && 3) || 1}
          loop={true}
          pagination={false}
          autoplay={{
            delay: 2500,
          }}
          modules={[Pagination, Autoplay]}
          className="owl-carousela wow fadeIn"
        >
          <SwiperSlide>
            <div className="wow-outer" style={{maxWidth:'97%'}}>
              <blockquote className="quote-modern quote-modern-big wow slideInLeft" >
                <svg
                  className="quote-modern-mark"
                  x="0px"
                  y="0px"
                  width="35px"
                  height="25px"
                  viewBox="0 0 35 25"
                >
                  <path d="M27.461,10.206h7.5v15h-15v-15L25,0.127h7.5L27.461,10.206z M7.539,10.206h7.5v15h-15v-15L4.961,0.127h7.5                L7.539,10.206z"></path>
                </svg>
                <div className="quote-modern-text">
                  <p>
                    They have highly qualified staff, efficient and dedicated to their work. They
                    handle our monthly bookkeeping and prepare monthly reports so I can more clearly
                    manage my business.
                  </p>
                </div>
                <div className="quote-modern-meta">
                  <div className="quote-modern-avatar">
                    <img
                      src="/images/testimonials-person-3-96x96.jpg"
                      alt=""
                      width="96"
                      height="96"
                    />
                  </div>
                  <div className="quote-modern-info">
                    <cite className="quote-modern-cite">Albert Williams</cite>
                    <p className="quote-modern-caption">CEO at Majestic</p>
                  </div>
                </div>
              </blockquote>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="wow-outer" style={{maxWidth:'97%'}}>
              <blockquote className="quote-modern quote-modern-big wow slideInLeft">
                <svg
                  className="quote-modern-mark"
                  x="0px"
                  y="0px"
                  width="35px"
                  height="25px"
                  viewBox="0 0 35 25"
                >
                  <path d="M27.461,10.206h7.5v15h-15v-15L25,0.127h7.5L27.461,10.206z M7.539,10.206h7.5v15h-15v-15L4.961,0.127h7.5                L7.539,10.206z"></path>
                </svg>
                <div className="quote-modern-text">
                  <p>
                    As a result of their excellent work, my business has been able to increase
                    profitability by simplifying employee payments, tax filing, and more with the
                    right payroll service.
                  </p>
                </div>
                <div className="quote-modern-meta">
                  <div className="quote-modern-avatar">
                    <img
                      src="/images/testimonials-person-1-96x96.jpg"
                      alt=""
                      width="96"
                      height="96"
                    />
                  </div>
                  <div className="quote-modern-info">
                    <cite className="quote-modern-cite">Kelly Spencer</cite>
                    <p className="quote-modern-caption">Private Entrepreneur</p>
                  </div>
                </div>
              </blockquote>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="wow-outer" style={{maxWidth:'97%'}}>
              <blockquote className="quote-modern quote-modern-big wow slideInLeft">
                <svg
                  className="quote-modern-mark"
                  x="0px"
                  y="0px"
                  width="35px"
                  height="25px"
                  viewBox="0 0 35 25"
                >
                  <path d="M27.461,10.206h7.5v15h-15v-15L25,0.127h7.5L27.461,10.206z M7.539,10.206h7.5v15h-15v-15L4.961,0.127h7.5                L7.539,10.206z"></path>
                </svg>
                <div className="quote-modern-text">
                  <p>
                    Avila's Accounting & Tax Services provides me with advice and takes the time to
                    prepare my taxes accurately. They know you well and your ever-changing tax
                    situation.
                  </p>
                </div>
                <div className="quote-modern-meta">
                  <div className="quote-modern-avatar">
                    <img
                      src="/images/testimonials-person-2-96x96.jpg"
                      alt=""
                      width="96"
                      height="96"
                    />
                  </div>
                  <div className="quote-modern-info">
                    <cite className="quote-modern-cite">Harold Campbell</cite>
                    <p className="quote-modern-caption">Tax Client</p>
                  </div>
                </div>
              </blockquote>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}

export default Testimonial
