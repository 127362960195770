import { Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Banner = () => {
  const { t } = useTranslation()
  return (
    <section className="bg-image-2 section section-xl pt-6 pb-0 banner-container ">
      <div className=" w-100 mt-0">
        <div className="row justify-content-center align-items-center p-0">
          <div className="blue-background"></div>

          {/* Imagen que mantiene su tamaño fijo */}
          <div className="col-lg-5 col-12 order-2 order-lg-1 text-center  ">
            <img src="/images/person.png" className="person-banner" />
          </div>
          {/* Textos informativos */}
          <div className="col-lg-7 col-12 order-1 order-lg-2 mt-5 p-3 text-center text-lg-start  section-text ">
            <div className="text-white mb-4">
              <h3 className="text-white fs-1 fs-lg-3">{t('banner.first_step')}</h3>
              <h3 className="fw-bold text-white fs-1 fs-lg-3">{t('banner.company_success')}</h3>
            </div>

            <p className="text-white text-lg-start">{t('banner.company_leader')}</p>
            <Link
              className="button light-btn button-winona wow slideInDown"
              to="/contact"
              placeholder=""
            >
              {t('banner.make_appointment')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
