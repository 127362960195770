import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby-plugin-react-i18next'

const Serv = () => {
  const { t, i18n } = useTranslation()

  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            name
            id
            image
            descriptionDetail
            slug
            lang
          }
        }
      }
    }
  `)

  // Filtramos los resultados según el idioma activo
  const services = data.allServicesJson.edges.filter(({ node }) => node.lang === i18n.language)

  return (
    <section className="section-lg text-center" style={{ marginBottom: '3rem' }}>
      <div className="container">
        <h3>{t('servicesTitle')}</h3>
        <p>
          <span className="text-width-1">{t('servicesDescription')}</span>
        </p>
        <div className="row row-50 row-xxl-70 offset-top-2">
          {services.map(({ node }) => (
            <div key={node.id} className="col-sm-6 col-lg-3">
              <article className="thumbnail-light">
                <Link
                  className="thumbnail-light-media"
                  to={`/services/${node.slug}`}
                  placeholder=""
                >
                  <img
                    className="thumbnail-light-image"
                    src={`/images/${node.image}`}
                    alt="service-img"
                  />
                </Link>
                <h5 className="thumbnail-dark-title">
                  <Link to={`/services/${node.slug}`} placeholder="">
                    {node.name}
                  </Link>
                </h5>
              </article>
            </div>
          ))}
          {/* <div className="col-sm-6 col-lg-3">
            <article className="thumbnail-light">
              <a className="thumbnail-light-media" href="single-service.html">
                <img
                  className="thumbnail-light-image"
                  src="/images/service-thumbnail-1-270x300.jpg"
                  alt=""
                  width="270"
                  height="300"
                />
              </a>
              <h5 className="thumbnail-light-title">
                <a href="single-service.html">Cash Management</a>
              </h5>
            </article>
          </div>
          <div className="col-sm-6 col-lg-3">
            <article className="thumbnail-light">
              <a className="thumbnail-light-media" href="single-service.html">
                <img
                  className="thumbnail-light-image"
                  src="/images/service-thumbnail-2-270x300.jpg"
                  alt=""
                  width="270"
                  height="300"
                />
              </a>
              <h5 className="thumbnail-light-title">
                <a href="single-service.html">Payments</a>
              </h5>
            </article>
          </div>
          <div className="col-sm-6 col-lg-3">
            <article className="thumbnail-light">
              <a className="thumbnail-light-media" href="single-service.html">
                <img
                  className="thumbnail-light-image"
                  src="/images/service-thumbnail-3-270x300.jpg"
                  alt=""
                  width="270"
                  height="300"
                />
              </a>
              <h5 className="thumbnail-light-title">
                <a href="single-service.html">M&A Assistance</a>
              </h5>
            </article>
          </div>
          <div className="col-sm-6 col-lg-3">
            <article className="thumbnail-light">
              <a className="thumbnail-light-media" href="single-service.html">
                <img
                  className="thumbnail-light-image"
                  src="/images/service-thumbnail-4-270x300.jpg"
                  alt=""
                  width="270"
                  height="300"
                />
              </a>
              <h5 className="thumbnail-light-title">
                <a href="single-service.html">Local Expertise</a>
              </h5>
            </article>
          </div>
          <div className="col-sm-6 col-lg-3">
            <article className="thumbnail-light">
              <a className="thumbnail-light-media" href="single-service.html">
                <img
                  className="thumbnail-light-image"
                  src="/images/service-thumbnail-5-270x300.jpg"
                  alt=""
                  width="270"
                  height="300"
                />
              </a>
              <h5 className="thumbnail-light-title">
                <a href="single-service.html">Video & Photo Production</a>
              </h5>
            </article>
          </div>
          <div className="col-sm-6 col-lg-3">
            <article className="thumbnail-light">
              <a className="thumbnail-light-media" href="single-service.html">
                <img
                  className="thumbnail-light-image"
                  src="/images/service-thumbnail-6-270x300.jpg"
                  alt=""
                  width="270"
                  height="300"
                />
              </a>
              <h5 className="thumbnail-light-title">
                <a href="single-service.html">Graphic Design</a>
              </h5>
            </article>
          </div>
          <div className="col-sm-6 col-lg-3">
            <article className="thumbnail-light">
              <a className="thumbnail-light-media" href="single-service.html">
                <img
                  className="thumbnail-light-image"
                  src="/images/service-thumbnail-7-270x300.jpg"
                  alt=""
                  width="270"
                  height="300"
                />
              </a>
              <h5 className="thumbnail-light-title">
                <a href="single-service.html">Strategy</a>
              </h5>
            </article>
          </div>
          <div className="col-sm-6 col-lg-3">
            <article className="thumbnail-light">
              <a className="thumbnail-light-media" href="single-service.html">
                <img
                  className="thumbnail-light-image"
                  src="/images/service-thumbnail-8-270x300.jpg"
                  alt=""
                  width="270"
                  height="300"
                />
              </a>
              <h5 className="thumbnail-light-title">
                <a href="single-service.html">Branding</a>
              </h5>
            </article>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default Serv
