import React from 'react'
import { useTranslation } from 'react-i18next'

const WhyChooseUs = () => {
  const { t } = useTranslation()
  return (
    <section className="section section-lg why-choose-section ">
      <div className="container mt-4">
        <div className="row">
          <div className="col-12 p-8">
            <h3 className="why-choose-us-title text-md-start text-center fw-medium">{t('why_choose_us.title')}</h3>
            <h4 className="text-start mt-2 why-choose-paragraph">
            {t('why_choose_us.paragraph')}
            </h4>

            <div
              className="section section-lg bg-gray-700 bg-image "
              style={{ backgroundImage: 'url(/images/why-choose-services.png)', height: '328px' }}
            ></div>
            <div className="row mt-5 ">
              <div className="col-md-12">
                <div className="row first-row ">
                  <div className="row content-wrapper ">
                    <div className="col-sm-2 mt-2 col-3 col-xl-1 " >
                      <img  src="/images/light-bulb.svg" alt="Check Icon" />
                    </div>
                    <div className="why-choose-first col-sm-10 col-9 ">
                      <h5>{t('why_choose_us.experience.title')}</h5>
                      <p>
                      {t('why_choose_us.experience.description')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>{' '}
            </div>
            <ul className="d-flex">
              <div className="row mt-2">
                <div className="col-xl-6">
                  <li>
                    <div className="row content-wrapper">
                    <div className="col-sm-2 col-3 mt-2">
                        <img className="img-fluid" src="/images/hands-open.svg" alt="Check Icon" />
                      </div>
                      <div className="col-sm-10 col-9">
                        <h5>{t('why_choose_us.personalized_service.title')}</h5>
                        <p className="three-lines">
                        {t('why_choose_us.personalized_service.description')}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row content-wrapper d-flex flex-row flex-sm-row">
                      <div className="col-sm-2 col-3 mt-2">
                        <img src="/images/hand-sale.svg" alt="Check Icon" />
                      </div>
                      <div className="col-sm-10 col-9">
                        <h5>{t('why_choose_us.competitive_pricing.title')}</h5>
                        <p className="three-lines">
                        {t('why_choose_us.competitive_pricing.description')}
                        </p>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-6">
                  <li>
                    <div className="row content-wrapper">
                    <div className="col-sm-2 col-3 mt-2">
                        <img src="/images/compass.svg" alt="Check Icon" />
                      </div>
                      <div className="col-sm-10 col-9">
                        <h5>{t('why_choose_us.attention_to_detail.title')}</h5>
                        <p className="three-lines">
                        {t('why_choose_us.attention_to_detail.description')}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row content-wrapper ">
                    <div className="col-sm-2 col-3 mt-2">
                        <img src="/images/cicle.svg" alt="Check Icon" />
                      </div>
                      <div className="col-sm-10 col-9">
                        <h5>{t('why_choose_us.comprehensive_services.title')}</h5>
                        <p className="three-lines">
                        {t('why_choose_us.comprehensive_services.description')}
                        </p>
                      </div>
                    </div>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
