import React from 'react'
import Breadcrumbs from '@/Common/Breadcrumbs'
import FW from '@/components/Services/FewWords'
import Serv from './Serv'
import Banner from './Banner'
import Testimonial from './Testimonial'
import WhyChooseUs from './WhyChooseUs'
import Pricing from './Pricing'
import Subscribe from './Subscribe'
import { useTranslation } from 'react-i18next'

const Se = () => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        subTitle={t('servicesTitle')}
        title={t('servicesTitle')}
        routes={[
          { to: '/', val: t('home') },
          { to: '#', val: t('servicesTitle') },
        ]}
      />
      <WhyChooseUs />
      <Serv />
      <Banner />
      <Testimonial />
      <Pricing />
    <Subscribe/>
      
      
    </>
  )
}

export default Se
