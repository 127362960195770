import { Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Pricing = () => {
  const { t } = useTranslation()
  return (
    <section id="pricing" className="section section-lg">
      <div className="container-fluid ">
        <div className="container p-4" style={{ maxWidth: '995px' }}>
          <h3 className="text-center">{t('pricing.best_plans')}</h3>
          <h5 className="text-center" style={{ marginBottom: '3rem' }}>
            {t('pricing.comparison_chart')}
          </h5>
          <div className="row">
            <div className="col-lg-4 col-lg-4 custom-padding-left">
              <div className="card h-90 blue-card p-2 mt-3">
                <div className="card-body ">
                  <div className="text-center text-white p-3 under">
                    <h5 className="card-title text-white">{t('pricing.starter_plan')}</h5>
                    <p className="text-white h6 mt-1 mb-0">{t('pricing.starting_from')}</p>
                    <div>
                      <span className="text-white bigger-font bold-text">$10</span>
                      <span> /{t('pricing.month')}</span>
                    </div>
                  </div>

                  <p className="card-text text-center text-white">
                    {t('pricing.starter_plan_description')}
                  </p>
                  <Link className="start-plan" to="/contact" placeholder="">
                    {t('pricing.start_this_plan')}
                  </Link>
                  <h4 className="text-white under">{t('pricing.features')}</h4>
                  <ul className="text-white">
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" />{' '}
                      {t('pricing.expense_tracking')}
                    </li>
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" /> {t('pricing.invoicing')}
                    </li>
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" />{' '}
                      {t('pricing.bank_reconciliation')}
                    </li>
                    <li className="row pt-0 mt-0">
                      <div
                        className="col-1"
                        style={{ width: '35px', paddingRight: '0px', paddingLeft: '16px' }}
                      >
                        <img style={{ width: '60px' }} src="/images/check.svg" alt="Check Icon" />
                      </div>

                      <div className="col-9" style={{ paddingRight: '0', paddingLeft: '7px' }}>
                        <span>{t('pricing.profit_loss_statements')}</span>{' '}
                      </div>
                    </li>
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" /> {t('pricing.balance_sheets')}
                    </li>
                    <li className="" style={{ marginBottom: '50px' }}>
                      <img src="/images/check.svg" alt="Check Icon" />{' '}
                      {t('pricing.affordable_pricing')}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 custom-padding ">
              <div className="card h-100 white-card p-md-2 shadow">
                <div className="card-body ">
                  <div className="text-center p-3 lines">
                    <h5 className="card-title">
                      <strong>Medium</strong>
                    </h5>
                    <p className=" h6 mt-1 mb-0">{t('pricing.starting_from')}</p>
                    <div>
                      <span className="bigger-font bold-text" style={{ color: '#0F4585' }}>
                        $80
                      </span>
                      <span> /{t('pricing.month')}</span>
                    </div>
                  </div>

                  <p className="text-center">{t('pricing.medium_plan_description')}</p>
                  <Link
                    className="button light-btn button-winona wow slideInDown"
                    style={{ padding: '14px', paddingInline: '38px' }}
                    placeholder=""
                    to="/contact"
                  >
                    {t('pricing.start_this_plan')}
                  </Link>
                  <h4 className="lines">{t('pricing.features')}</h4>
                  <ul className="text-black" style={{ color: '#1F1F1F' }}>
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" />{' '}
                      {t('pricing.expense_tracking')}
                    </li>
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" /> {t('pricing.invoicing')}
                    </li>
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" />{' '}
                      {t('pricing.bank_reconciliation')}
                    </li>
                    <li className="row pt-0 mt-0">
                      <div
                        className="col-1"
                        style={{ width: '35px', paddingRight: '0px', paddingLeft: '16px' }}
                      >
                        <img style={{ width: '60px' }} src="/images/check.svg" alt="Check Icon" />
                      </div>

                      <div className="col-9" style={{ paddingRight: '0', paddingLeft: '7px' }}>
                        <span>{t('pricing.profit_loss_statements')}</span>{' '}
                      </div>
                    </li>
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" /> {t('pricing.balance_sheets')}
                    </li>
                    <li className="" style={{ marginBottom: '50px' }}>
                      <img src="/images/check.svg" alt="Check Icon" />{' '}
                      {t('pricing.affordable_pricing')}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 custom-padding-right">
              <div className="card h-90 blue-card p-2 mt-lg-3">
                <div className="card-body ">
                  <div className="text-center text-white p-3 under">
                    <h5 className="card-title text-white">Complete</h5>
                    <p className="text-white h6 mt-1 mb-0">{t('pricing.starting_from')}</p>
                    <div>
                      <span className="text-white bigger-font bold-text">$80</span>
                      <span> /{t('pricing.month')}</span>
                    </div>
                  </div>

                  <p className="card-text text-center text-white">
                    {t('pricing.complete_plan_description')}
                  </p>
                  <Link className="start-plan" to="/contact" placeholder="">
                    {t('pricing.start_this_plan')}
                  </Link>
                  <h4 className="text-white under">{t('pricing.features')}</h4>
                  <ul className="text-white" style={{ marginBottom: '0px' }}>
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" />{' '}
                      {t('pricing.expense_tracking')}
                    </li>
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" /> {t('pricing.invoicing')}
                    </li>
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" />{' '}
                      {t('pricing.bank_reconciliation')}
                    </li>
                    <li className="row pt-0 mt-0">
                      <div
                        className="col-1"
                        style={{ width: '35px', paddingRight: '0px', paddingLeft: '16px' }}
                      >
                        <img style={{ width: '60px' }} src="/images/check.svg" alt="Check Icon" />
                      </div>

                      <div className="col-9" style={{ paddingRight: '0', paddingLeft: '7px' }}>
                        <span>{t('pricing.profit_loss_statements')}</span>{' '}
                      </div>
                    </li>
                    <li className="">
                      <img src="/images/check.svg" alt="Check Icon" /> {t('pricing.balance_sheets')}
                    </li>
                    <li className="last mt-0 row">
                      <div
                        className="col-1"
                        style={{ width: '35px', paddingRight: '0px', paddingLeft: '16px' }}
                      >
                        <img style={{ width: '60px' }} src="/images/check.svg" alt="Check Icon" />
                      </div>

                      <div className="col-9" style={{ paddingRight: '0', paddingLeft: '7px' }}>
                        <span>{t('pricing.multi_currency_support')}</span>{' '}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Pricing
