import React from 'react'
import { useTranslation } from 'react-i18next'

const Subscribe = () => {
  const { t } = useTranslation()

  const handleClick = () => {
    const subscribeButton = document.getElementById('subscribe-button')
    subscribeButton?.click()
  }

  return (
    <section className="section section-xs text-center p-24 subscribe-services-section">
      <div className="container">
        <div className="row">
          <div className=" d-flex flex-column flex-md-row justify-content-between align-items-center align-items-md-start">
            <h3 className="col-12 wow-outer text-white col-md-9 text-center text-md-start">
              <span className="wow slideInDown">
                {t('subscribeSection.title')}
                <br />
                <span className="fw-bold">{t('subscribeSection.subtitle')}</span>
              </span>
            </h3>
            <button
              className="button light-btn button-winona wow slideInDown col-md-3 pr-3"
              data-wow-delay=".1s"
              onClick={handleClick}
            >
              {t('subscribeSection.subscribeButton')}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Subscribe
